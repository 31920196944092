import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../img/logo.jpg";

const Menu = () => {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/admin" className="brand-link">
          <img
            src={logo}
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Super Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item has-treeview">
                <Link to="/admin" className="nav-link">
                  <span>
                    <FaHome />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>
              {/*============================ product Setting Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Add-Edit
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                <li className="nav-item">
                    <Link to="/admin/saloon/addcategory" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Category</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/saloon/listcategory" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>List Category</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/saloon/addservices" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Service</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/saloon/servicelist" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>List Services</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/*============================ product Setting end ================================*/}

              {/*============================ Orders Details start ================================*/}
              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Orders Details
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Orders
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/saloon/bookingreceived" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Booked Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/saloon/bookingaccepted" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Accepted Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/saloon/bookingintransit" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Intransit Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/saloon/bookingcompleted"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Completed Orders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/saloon/bookingcancelled" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Cancelled Order</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ Order Details end ================================*/}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};
export default Menu;
