import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { createSaloon, validateSlugUrl } from "../../redux/saloon/SaloonSlice";

const UpdateSaloon = () => {
    const { superCatTotal } = useSelector((store) => store.superCategory);

    const [brand, setBrand] = useState("");
    const [slugUrl, setSlugUrl] = useState("");
    const [show, setShow] = useState(Boolean(0));
    const [supercat, setSupercat] = useState("");
    const [supercatid, setSupercatid] = useState("");
    const [checkslugUrl, setCheckSlugUrl] = useState("");
    const [error, setError] = useState("");
    const [errorcolor, setErrorcolor] = useState("red");

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [area, setArea] = useState('')


    const locations = [
        { "name": "Ameerpet" },
        { "name": "Panjagutta" },
        { "name": "Somajiguda" },
        { "name": "Himayat Nagar" },
        { "name": "Kachiguda" },
        { "name": "Khairtabad" },
        { "name": "Karwan" },
        { "name": "Amberpet" },
        { "name": "Shamshabad" },
        { "name": "Adibatla" },
        { "name": "Maheshwaram" },
        { "name": "Shadnagar" },
        { "name": "Rajendranagar" },
        { "name": "Srisailam Highway" },
        { "name": "Miyapur" },
        { "name": "Pocharam" },
        { "name": "Bachupally" },
        { "name": "Nizampet" },
        { "name": "Bollaram" },
        { "name": "Yepral" },
        { "name": "Shamirpet" },
        { "name": "LB Nagar" },
        { "name": "Nagole" },
        { "name": "Uppal" },
        { "name": "Habsiguda" },
        { "name": "Ghatkesar" },
        { "name": "Nacharam" },
        { "name": "Peerzadiguda" },
        { "name": "Vanastalipuram" },
        { "name": "Gachibowli" },
        { "name": "Kondapur" },
        { "name": "Tellapur" },
        { "name": "Kukatpalli" },
        { "name": "Attapur" },
        { "name": "Kokapet" },
        { "name": "Patancheru" },
        { "name": "Madhapur" },
        { "name": "Appa Junction" }
    ]


    const verifyslugurl = async (e) => {
        const value = e.target.value;
        setName(value);
        setCheckSlugUrl("");
        const nameSlug = value
            .trim()
            .toLowerCase()
            .replace(" ", "-")
            .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
            .replace("--", "-")
            .replace("---", "-")
            .replace("----", "-")
            .replace("/", "-")
            .replace("//", "-")
            .replace("///", "-");
        setSlugUrl(nameSlug);
        const response = await dispatch(validateSlugUrl(nameSlug))
        if (response.payload.success) {
            setError("Saloon alredy exist");
            setErrorcolor("red");
        } else if (nameSlug.length <= 2) {
            setErrorcolor("red");
            setError("minimum 3 letters");
            setCheckSlugUrl("");
        } else if (nameSlug.length >= 3) {
            setErrorcolor(" ");
            setError("");
            setCheckSlugUrl(nameSlug);
        }
    };
    const dispatch = useDispatch();

    const selectArea = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setArea(text);
    };
    const handlesubmit = async (e) => {
        e.preventDefault();
        if (checkslugUrl !== "") {

            const formData = {
                name: name,
                slugUrl: checkslugUrl,
                phone:phone,
                address:address,
                area:area,
                show: show,
            };
            console.log(formData);
            const response = await dispatch(createSaloon(formData));
            if(response.payload.success){
                alert('Saloon Created');
                setName('');
                setPhone('');
                setAddress('');
                setArea('');
                setShow('');
                setCheckSlugUrl('');
            }
        } else {
            alert("please enter correct values");
        }
    };
    return (
        <>
            <Fragment>
                <div className="content-wrapper">
                    <div className="employeesContainer">
                        <form onSubmit={handlesubmit}>
                            <h4 className="p-4">Update Saloon</h4>
                            <div className="card m-4">
                                <div className="card-footer">
                                    <h5>Update Saloon</h5>
                                </div>
                                <div className="card-body">
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Name *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Name"
                                                value={name}
                                                required
                                                onChange={(e) => verifyslugurl(e)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Phone *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Phone"
                                                value={phone}
                                                required
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Address *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Address"
                                                value={address}
                                                required
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Area
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Floating label select example"
                                                onChange={(e) => {
                                                    selectArea(e);
                                                }}
                                                value={superCatTotal.name}
                                                name={superCatTotal.name}
                                            >
                                                <option value="0">{"Select Super Category"}</option>
                                                {locations.map((data, index) => (
                                                    <option
                                                        key={data.index}
                                                        value={data.name}
                                                        name={data.name}
                                                        required
                                                        selected={area===data.name}
                                                    >
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="show"
                                                    checked={show === true}
                                                    onChange={(e) => {
                                                        setShow(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Show</Form.Label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-footer ">
                                    {checkslugUrl !== "" &&
                                        phone !== "" &&
                                        name !== "" &&
                                        area !== "" &&
                                        address !== ""  ? (
                                        <button className="btn btn-primary" type="submit" style={{ borderRadius: '5px' }}>
                                            Add Saloon
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary" disabled type="submit" style={{ borderRadius: '5px' }}>
                                            Add Saloon
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default UpdateSaloon;
