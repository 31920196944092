import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { act } from "react";

const initialState = {
  loading: false,
  serviceList: [],
  desktopimage:'',
  mobileimage:'',
  imageLoading:false
};
export const createSaloonService = createAsyncThunk(
  "saloon/createSaloonService",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/saloonServices/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSaloonServiceList = createAsyncThunk(
  "saloon/SaloonServiceList",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/saloonServices/servicesbysupercatid/${formData._id}`;
      const resp = await axios.get(url);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSaloonServiceById=createAsyncThunk(
    "saloon/SaloonServiceById",
    async(formData,thunkAPI)=>{
        try{
            const url=`${Baseurl}/api/v1/saloonServices/singleService/${formData}`;
            console.log(url,'url');
            const resp=await axios.get(url);
            console.log(resp.data,"resp.data");
            return resp.data;
        }catch(error){
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
) 
export const saloonServiceImages = createAsyncThunk(
    "saloon/saloonServiceImages",
    async (formData, thunkAPI) => {
        console.log(formData,'formData');
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/saloonServices/serviceImage`;
        const resp = await axios.post(url, formData, config);
        console.log(resp.data,'resp.data');
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("brandimages Not create");
      }
    }
  );
export const validateSaloonServiceSlugUrl = createAsyncThunk(
    "saloon/validateSaloonServiceSlugUrl",
    async (slugUrl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new name",
      }
      try {
        const url = `${Baseurl}/api/v1/saloonServices/slugUrl/${slugUrl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

export const updateSaloonServices = createAsyncThunk(
  "saloon/updateSaloonServices",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/saloonServices/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteSaloonService = createAsyncThunk(
  "saloon/deleteSaloonService",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/saloonServices/delete/${formData._id}`;
      const resp = await axios.delete(url);
      console.log(resp.data, "resp.data");
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const saloonServiceSlice = createSlice({
  name: "saloonService",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSaloonService.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSaloonService.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceList = [action.payload.saloon, ...state.serviceList];
      })
      .addCase(createSaloonService.rejected, (state) => {
        state.loading = true;
      })
      .addCase(saloonServiceImages.pending, (state) => {
        state.imageLoading = true;
      })
      .addCase(saloonServiceImages.fulfilled, (state, action) => {
        state.imageLoading = false;
        state.mobileimage = action.payload.mobileimages;
        state.desktopimage = action.payload.desktopImages;
      })
      .addCase(saloonServiceImages.rejected, (state) => {
        state.imageLoading = true;
      })
      .addCase(getSaloonServiceList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSaloonServiceList.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceList = [...action.payload.services];
      })
      .addCase(getSaloonServiceList.rejected, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloonServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloonServices.fulfilled, (state, action) => {
          if(action.payload.success){
            state.loading = false;
            state.serviceList = state.serviceList.filter((individualSaloon)=>
                individualSaloon._id!==action.payload.saloon._id
            )
            state.serviceList = [action.payload.saloon, ...state.serviceList];
        }
      })
      .addCase(updateSaloonServices.rejected, (state) => {
        state.loading = true;
      })
      .addCase(deleteSaloonService.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSaloonService.fulfilled, (state, action) => {
          if(action.payload.success){
            state.loading = false;
            state.serviceList = state.serviceList.filter((individualSaloon)=>
                individualSaloon._id!==action.payload.saloon._id
            )
        }
      })
      .addCase(deleteSaloonService.rejected, (state) => {
        state.loading = true;
      });
  },
});
export const {
} = saloonServiceSlice.actions;
export default saloonServiceSlice.reducer;