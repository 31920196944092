import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

export const addressupdate = createAsyncThunk(
  "auth/addressupdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/bulkaddress/${formData.clientId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("address Not create");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: JSON.parse(localStorage.getItem("authState"))?.isAuth
      ? JSON.parse(localStorage.getItem("authState")).isAuth
      : false,
    token: JSON.parse(localStorage.getItem("authState"))?.token
      ? JSON.parse(localStorage.getItem("authState")).token
      : "",
    name: JSON.parse(localStorage.getItem("authState"))?.name
      ? JSON.parse(localStorage.getItem("authState")).name
      : "",
    mobile: JSON.parse(localStorage.getItem("authState"))?.mobile
      ? JSON.parse(localStorage.getItem("authState")).mobile
      : "",
    clientid: JSON.parse(localStorage.getItem("authState"))?.clientid
      ? JSON.parse(localStorage.getItem("authState")).clientid
      : "",
    adminData: JSON.parse(localStorage.getItem("authState"))?.admin
    ? JSON.parse(localStorage.getItem("authState")).admin
    : "",
  },
  reducers: {
    signin(state, action) {
      console.log(action.payload,'action.payload');
      localStorage.setItem("authState", JSON.stringify(action.payload)); // authState object has token and isAuth property.
      state.isAuth = true;
      state.adminData=action.payload.admin
    },
    signout(state, action) {
      localStorage.removeItem("authState");
      // localStorage.clear();
      state.isAuth = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
