import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { createSaloon, serviceImages, updateSaloonService, validateSlugUrl } from "../../redux/saloon/SaloonSlice";
import { useParams } from "react-router-dom";
import { createSaloonService, saloonServiceImages, updateSaloonServices, validateSaloonServiceSlugUrl } from "../../redux/salonService/SaloonServiceSlice";

const EditSaloonServices = () => {
    const { adminData } = useSelector((store) => store.auth)
    const { categorytotal, } = useSelector(
        (store) => store.saloonCategoryAll
    );
    const { desktopimage, imageLoading, serviceList } = useSelector(
        (store) => store.saloonService
    );
    const params = useParams()
    const [slugUrl, setSlugUrl] = useState("");
    const [supercatid, setSupercatid] = useState("");
    const [supercat, setSupercat] = useState("");
    const [catbySupercat, setCatbySupercat] = useState([]);
    const [catid, setCatid] = useState("");
    const [cat, setCat] = useState("");
    const [checkslugUrl, setCheckSlugUrl] = useState("");
    const [error, setError] = useState("");
    const [errorcolor, setErrorcolor] = useState("red");

    const [name, setName] = useState('')
    const [serviceId, setServiceId] = useState('')
    const [price, setPrice] = useState('')
    const [mrp, setMrp] = useState('')
    const [duration, setDuration] = useState('')
    const [mustTry, setMustTry] = useState(Boolean(0));
    const [bestSeller, setBestSeller] = useState(Boolean(0));
    const [newLaunch, setNewLaunch] = useState(Boolean(0));
    const [hotDeal, setHotDeal] = useState(Boolean(0));
    const [indemand, setIndemand] = useState(Boolean(0));
    const [trending, setTrending] = useState(Boolean(0));

    const [inputs, setInputs] = useState([{ service: '' }])

    useEffect(() => {
        let servicesList = []
        console.log(params.id, 'params.id');
        if (params.id) {
            const getServices = serviceList.find((indService) =>
                indService._id == params.id
            )
            console.log(getServices, 'getServices')
            setServiceId(getServices._id)
            setBestSeller(getServices.bestSeller)
            setCat(getServices.category)
            setCatid(getServices.categoryId)
            setDuration(getServices.duration)
            setHotDeal(getServices.hotDeal)
            setIndemand(getServices.inDemand)
            setMrp(getServices.mrp)
            setMustTry(getServices.mustTry)
            setName(getServices.name)
            setCheckSlugUrl(getServices.name)
            setNewLaunch(getServices.newLaunch)
            setPrice(getServices.price)
            setSupercat(getServices.superCategory)
            setSupercatid(getServices.superCategoryId)
            dispatch(saloonServiceImages({ desktopImage: getServices.image }));
            const getData = getServices.services.map((indService) => {
                let service = { service: indService.service }
                return servicesList.push(service)
            })
            setInputs(servicesList)
        }
    }, [params])

    useEffect(() => {
        if (adminData) {
            setSupercatid(adminData.saloonid)
            setSupercat(adminData.name)
        }
    }, [adminData])

    const handleChange = (e, index) => {
        const newInputs = [...inputs];
        newInputs[index].service = e.target.value;
        setInputs(newInputs);
    };

    const addInput = () => {
        if (inputs[inputs.length - 1].service !== '') {
            setInputs([...inputs, { service: '' }]);
        }
    };

    const removeInput = (index) => {
        const newInputs = inputs.filter((_, i) => i !== index);
        setInputs(newInputs);
    };
    const verifyslugurl = async (e) => {
        const value = e.target.value;
        setName(value);
        setCheckSlugUrl("");
        const nameSlug = value
            .trim()
            .toLowerCase()
            .replace(" ", "-")
            .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
            .replace("--", "-")
            .replace("---", "-")
            .replace("----", "-")
            .replace("/", "-")
            .replace("//", "-")
            .replace("///", "-");
        setSlugUrl(nameSlug);
        const response = await dispatch(validateSaloonServiceSlugUrl(nameSlug))
        if (nameSlug.length <= 2) {
            setErrorcolor("red");
            setError("minimum 3 letters");
            setCheckSlugUrl("");
        } else if (nameSlug.length >= 3) {
            setErrorcolor(" ");
            setError("");
            setCheckSlugUrl(nameSlug);
        }
    };
    const dispatch = useDispatch();

    useEffect(() => {
        setCatbySupercat(categorytotal);
    }, [categorytotal]);


    const selectCat = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setCatid(value);
        setCat(text);
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        const formData = {
            _id: serviceId,
            superCategoryId: supercatid,
            superCategory: supercat,
            category: cat,
            categoryId: catid,
            name: name,
            price: price,
            mrp: mrp,
            duration: duration,
            image: desktopimage,
            services: inputs,
            mustTry: mustTry,
            bestSeller: bestSeller,
            newLaunch: newLaunch,
            hotDeal: hotDeal,
            inDemand: indemand,
            trending: trending,
        }
        console.log(formData, 'formData');
        const response = await dispatch(updateSaloonServices(formData));
        console.log(response.payload.salon);
        if (response.payload.success) {
            alert('Services Updated');
            setName('');
            setPrice('');
            setMrp('');
            setInputs([{ service: '' }])
            setMrp('')
            setDuration('')
            setMustTry(Boolean(0));
            setBestSeller(Boolean(0));
            setNewLaunch(Boolean(0));
            setHotDeal(Boolean(0));
            setTrending(Boolean(0));
            setIndemand(Boolean(0));
        }
    };
    const imageChange = (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(saloonServiceImages({ desktopImage: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };

    return (
        <>
            <Fragment>
                <div className="content-wrapper">
                    <div className="employeesContainer">
                        <form onSubmit={handlesubmit}>
                            <h4 className="p-4">Edit Services</h4>
                            <div className="card m-4">
                                <div className="card-footer">
                                    <h5>Edit Services</h5>
                                </div>
                                <div className="card-body">
                                    <Row className="g-2  m-2 ">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Super Category
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Super Category"
                                                value={supercat}
                                                required
                                                readOnly
                                            />
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Category
                                            </Form.Label>

                                            <Form.Select
                                                aria-label="Floating label select example"
                                                onChange={(e) => {
                                                    selectCat(e);
                                                }}
                                                value={catbySupercat._id}
                                                name={catbySupercat.name}
                                            >
                                                <option value="0">{"Select Category"}</option>
                                                {catbySupercat.map((data) => (
                                                    <option
                                                        key={data._id}
                                                        value={data._id}
                                                        name={data.name}
                                                        required
                                                        selected={data._id === catid ? true : false}
                                                    >
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Name *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Name"
                                                value={name}
                                                required
                                                onChange={(e) => verifyslugurl(e)}
                                            />
                                        </Col>
                                        <Col md>
                                            <Form.Label>Service Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={imageChange}
                                            />
                                            <p className="text-center mt-2">
                                                * The image width and height should be 480px * 360px
                                            </p>
                                            <div>
                                                {imageLoading ? (
                                                    <div></div>
                                                ) : (
                                                    <img
                                                        src={desktopimage}
                                                        width={150}
                                                        height={150}
                                                        alt="img"
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Add Services *
                                            </Form.Label>
                                            <div>
                                                {inputs.map((input, index) => (
                                                    <div key={index} style={{ marginBottom: '10px' }}>
                                                        <input
                                                            type="text"
                                                            value={input.service}
                                                            onChange={(e) => handleChange(e, index)}
                                                            style={{ marginRight: '10px', width: '80%', border: '1px solid #cccccc', padding: '5px', borderRadius: '4px' }}
                                                        />
                                                        {inputs.length > 1 && (
                                                            <button onClick={() => removeInput(index)} style={{ backgroundColor: '#ff3333', color: '#fff', border: '1px solid #ff3333', borderRadius: '3px' }}>Remove</button>
                                                        )}
                                                    </div>
                                                ))}
                                                <button onClick={addInput} style={{ backgroundColor: '#ff9900', color: '#fff', border: '1px solid #ff9900', padding: '3px 10px', borderRadius: '3px' }}>Add</button>
                                            </div>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Price *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Price"
                                                value={price}
                                                required
                                                onChange={(e) => setPrice(e.target.value)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Mrp *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Mrp"
                                                value={mrp}
                                                required
                                                onChange={(e) => setMrp(e.target.value)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Duration *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Duration"
                                                value={duration}
                                                required
                                                onChange={(e) => setDuration(e.target.value)}
                                            />
                                            <p style={{ color: errorcolor }} className="mt-2">
                                                {error}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2">
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="mustTry"
                                                    checked={mustTry === true}
                                                    onChange={(e) => {
                                                        setMustTry(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Must Try</Form.Label>
                                            </div>
                                        </Col>
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="bestSeller"
                                                    checked={bestSeller === true}
                                                    onChange={(e) => {
                                                        setBestSeller(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Best Seller</Form.Label>
                                            </div>
                                        </Col>
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="newLaunch"
                                                    checked={newLaunch === true}
                                                    onChange={(e) => {
                                                        setNewLaunch(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>New Launch</Form.Label>
                                            </div>
                                        </Col>
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="hotDeal"
                                                    checked={hotDeal === true}
                                                    onChange={(e) => {
                                                        setHotDeal(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Hot Deal</Form.Label>
                                            </div>
                                        </Col>
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="indemand"
                                                    checked={indemand === true}
                                                    onChange={(e) => {
                                                        setIndemand(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Indemand</Form.Label>
                                            </div>
                                        </Col>
                                        <Col className="d-flex ">
                                            <div className="me-4">
                                                <input
                                                    type="checkbox"
                                                    className="me-1"
                                                    value="trending"
                                                    checked={trending === true}
                                                    onChange={(e) => {
                                                        setTrending(e.target.checked);
                                                    }}
                                                />
                                                <Form.Label>Trending</Form.Label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-footer ">
                                    {checkslugUrl !== "" &&
                                        name !== "" &&
                                        price !== "" &&
                                        duration !== "" ? (
                                        <button className="btn btn-primary" type="submit" style={{ borderRadius: '5px' }}>
                                            Edit Service
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary" disabled type="submit" style={{ borderRadius: '5px' }}>
                                            Edit Service
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default EditSaloonServices;
