import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import BeatLoader from "react-spinners/BeatLoader";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { updateOrder } from "../../redux/order/OrderSlice";
import { clientUpdate, getClientByMob, walletTransitionPost } from "../../redux/client/ClientSlice";

const SaloonCancelledOrder = () => {
  const {adminData}=useSelector((store)=>store.auth)
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/ordershop/salooncancelledorders/${adminData.saloonid}`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        setOrders(orderTotal.reverse());
        setTempLoading(false);
      }
    };
    fetchData();
    // setInterval(async () => {
    //   fetchData();
    // }, 500);
  }, []);

  useEffect(() => {
    const result = orders.filter((order) => {
      return order._id.toLowerCase().slice(18).match(search.toLowerCase());
    });
    setFilterOrder(result);
  }, [search, orders]);

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Order ID",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            <Link to={`/admin/orderdetails/${params.row._id}`}>
              {params.row._id.slice(18)}
            </Link>
          </div>
        );
      },
    },

    {
      field: "ClientId",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row.ClientId.slice(18)}
          </div>
        );
      },
    },

    {
      field: "ClientName",
      headerName: "ClientName",
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Order DateTime",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column my-2">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
            <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
          </div>
        );
      },
    },
    {
      field: "TotalAmount",
      headerName: "Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Address",
      headerName: "Address",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 130,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "PaymentMode",
      headerName: "PaymentMode",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PaymentStatus",
      headerName: "PaymentStatus",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer">
              <h5>ORDER CANCELLED</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2 w-25">
                <Col md>
                  <Form.Control
                    type="text"
                    placeholder="Search by orderId"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </Col>
              </Row>
            </div>
            {temploading ? (
              <>
                <div className="d-flex justify-content-center loadingMain">
                  <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                  Order is being fetched
                  </h4>
                  <BeatLoader
                    color={"#36d7b7"}
                    loading={temploading}
                    size={10}
                    className="mt-2 mx-2"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={filterOrder}
                    columns={columns}
                    getRowId={(row) => row._id}
                    rowHeight={100}
                    getRowHeight={() => "auto"}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default SaloonCancelledOrder;
