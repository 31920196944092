import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/Athentication/AuthSlice";
import saloonReducer from "./redux/saloon/SaloonSlice";
import saloonCategoryReducer from "./redux/saloonCategory/SaloonCategorySlice";
import saloonServiceReducer from "./redux/salonService/SaloonServiceSlice";
import ordersReducer from "./redux/order/OrderSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    saloon:saloonReducer,
    saloonCategoryAll:saloonCategoryReducer,
    saloonService:saloonServiceReducer,
    orders: ordersReducer,
  },
});