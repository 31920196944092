import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import UpdateSaloon from "./pages/add-saloon/UpdateSaloon";
import { getSaloonList } from "./redux/saloon/SaloonSlice";
import AddSaloonServices from "./pages/add-saloon/AddSaloonServices";
import ServiceList from "./pages/add-saloon/ServiceList";
import SaloonBookedOrder from "./pages/saloonOrders/SaloonBookedOrders";
import SaloonAcceptedOrders from "./pages/saloonOrders/SaloonAcceptedOrders";
import SaloonIntransitOrder from "./pages/saloonOrders/SaloonIntransitorders";
import SaloonCompletedOrder from "./pages/saloonOrders/SaloonCompletedOrders";
import SaloonCancelledOrder from "./pages/saloonOrders/SaloonCancelledOrder";
import AddSaloonCategory from "./pages/add-saloon/AddSaloonCategory";
import { getSaloonCategory } from "./redux/saloonCategory/SaloonCategorySlice";
import ListSaloonCategory from "./pages/add-saloon/ListSaloonCategory";
import { getSaloonServiceList } from "./redux/salonService/SaloonServiceSlice";
import ListSaloonServices from "./pages/add-saloon/ListSaloonServices";
import EditSaloonCategory from "./pages/add-saloon/EditSaloonCategory";
import EditSaloonServices from "./pages/add-saloon/EditSaloonServices";

function App() {
  const dispatch = useDispatch();
  const {adminData}=useSelector((store)=>store.auth)
  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getsalooncategory
        if(adminData){
          const formData={
            _id:adminData.saloonid
          }
          dispatch(getSaloonCategory(formData));
          dispatch(getSaloonServiceList(formData));
        }
      } catch (error) {}
    };
    initialSetup();
  }, []);

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            {/* Saloon Orders */}
            <Route
              path="/admin/saloon/bookingreceived"
              element={<SaloonBookedOrder />}
            />
            <Route
              path="/admin/saloon/bookingaccepted"
              element={<SaloonAcceptedOrders />}
            />
            <Route
              path="/admin/saloon/bookingintransit"
              element={<SaloonIntransitOrder />}
            />
            <Route
              path="/admin/saloon/bookingcompleted"
              element={<SaloonCompletedOrder />}
            />
            <Route
              path="/admin/saloon/bookingcancelled"
              element={<SaloonCancelledOrder />}
            />
            {/* saloon */}
            <Route
              path="/admin/updatesaloon/:slugurl"
              element={<UpdateSaloon />}
            />
            <Route
              path="/admin/saloon/addservices"
              element={<AddSaloonServices />}
            />
            <Route
              path="/admin/saloon/editservices/:id"
              element={<EditSaloonServices />}
            />
            <Route
              path="/admin/saloon/addcategory"
              element={<AddSaloonCategory />}
            />
            <Route
              path="/admin/saloon/listcategory"
              element={<ListSaloonCategory />}
            />
            <Route
              path="/admin/saloon/editcategory/:id"
              element={<EditSaloonCategory />}
            />
            <Route path="/admin/saloon/servicelist" element={<ListSaloonServices />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
