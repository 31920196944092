import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { act } from "react";

const initialState = {
  loading: false,
  saloonList: [],
  desktopimage:'',
  mobileimage:'',
  imageLoading:false
};
export const createSaloon = createAsyncThunk(
  "saloon/createSaloon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/saloon/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSaloonList = createAsyncThunk(
  "saloon/saloonList",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/saloon/all`;
      const resp = await axios.get(url);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSaloonById=createAsyncThunk(
    "saloon/sallonById",
    async(formData,thunkAPI)=>{
        try{
            const url=`${Baseurl}/api/v1/saloon/singleSaloon/${formData}`;
            console.log(url,'url');
            const resp=await axios.get(url);
            console.log(resp.data,"resp.data");
            return resp.data;
        }catch(error){
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
) 
export const serviceImages = createAsyncThunk(
    "brand/serviceImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/saloon/saloonImage`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("saloonimages Not create");
      }
    }
  );
export const validateSlugUrl = createAsyncThunk(
    "saloon/validateSlugUrl",
    async (slugUrl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new name",
      }
      try {
        const url = `${Baseurl}/api/v1/saloon/slugUrl/${slugUrl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

export const updateSaloon = createAsyncThunk(
  "saloon/updateSaloon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/saloon/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateSaloonService = createAsyncThunk(
  "saloon/updateSaloonService",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/saloon/updateService/${formData._id}`;
      console.log(url,'url');
      const resp = await axios.put(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteSaloon = createAsyncThunk(
  "saloon/deleteSaloon",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/saloon/delete/${formData._id}`;
      const resp = await axios.delete(url);
      console.log(resp.data, "resp.data");
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const saloonSlice = createSlice({
  name: "saloon",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSaloon.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSaloon.fulfilled, (state, action) => {
        state.loading = false;
        state.saloonList = [action.payload.saloon, ...state.saloonList];
      })
      .addCase(createSaloon.rejected, (state) => {
        state.loading = true;
      })
      .addCase(serviceImages.pending, (state) => {
        state.imageLoading = true;
      })
      .addCase(serviceImages.fulfilled, (state, action) => {
        state.imageLoading = false;
        state.mobileimage = action.payload.mobileimages;
        state.desktopimage = action.payload.desktopImages;
      })
      .addCase(serviceImages.rejected, (state) => {
        state.imageLoading = true;
      })
      .addCase(getSaloonList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSaloonList.fulfilled, (state, action) => {
        state.loading = false;
        state.saloonList = [...action.payload.saloon];
      })
      .addCase(getSaloonList.rejected, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloon.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloon.fulfilled, (state, action) => {
          if(action.payload.success){
            state.loading = false;
            state.saloonList = state.saloonList.filter((individualSaloon)=>
                individualSaloon._id!==action.payload.saloon._id
            )
            state.saloonList = [action.payload.saloon, ...state.saloonList];
        }
      })
      .addCase(updateSaloon.rejected, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloonService.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSaloonService.fulfilled, (state, action) => {
          if(action.payload.success){
            state.loading = false;
            state.saloonList = state.saloonList.filter((individualSaloon)=>
                individualSaloon._id!==action.payload.saloon._id
            )
            state.saloonList = [action.payload.saloon, ...state.saloonList];
        }
      })
      .addCase(updateSaloonService.rejected, (state) => {
        state.loading = true;
      })
      .addCase(deleteSaloon.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSaloon.fulfilled, (state, action) => {
          if(action.payload.success){
            state.loading = false;
            state.saloonList = state.saloonList.filter((individualSaloon)=>
                individualSaloon._id!==action.payload.saloon._id
            )
        }
      })
      .addCase(deleteSaloon.rejected, (state) => {
        state.loading = true;
      });
  },
});
export const {
} = saloonSlice.actions;
export default saloonSlice.reducer;