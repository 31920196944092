import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categorytotal: localStorage.getItem("saloonCategorytotal")
    ? JSON.parse(localStorage.getItem("saloonCategorytotal"))
    : [],

  mobileimage: "",
  desktopimage: "",
  isCatmobileImageLoading: true,
  isDeskImageLoading: true,
  isLoading: true,
  categoryLoading: true,
  deleteCatLoading: true,
  checkSlugurl: true,
};

export const getSaloonCategory = createAsyncThunk(
  "saloonCategory/getSaloonCategory",
  async (formData,thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/saloonCategory/categorybysupercatid/${formData._id}`;
      console.log(url,'url');
      const resp = await axios(url);
      console.log(resp.data,'resp.data');
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const saloonCategoryPost = createAsyncThunk(
  "saloonCategory/categorypost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/saloonCategory/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const categoryMobileImage = createAsyncThunk(
  "saloonCategory/categoryMobileImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/saloonCategory/mobileimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const categoryDesktopImage = createAsyncThunk(
  "saloonCategory/categoryDesktopImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/saloonCategory/desktopimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const saloonCategoryImages = createAsyncThunk(
  "saloonCategory/categoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/saloonCategory/categoryimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);

export const validateSaloonCatSlugUrl = createAsyncThunk(
  "saloonCategory/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/saloonCategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const saloonCategoryUpdate = createAsyncThunk(
  "saloonCategory/categoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        // headers: {
        //   "Content-Type":
        //     "multipart/form-data; boundary=AaB03x" +
        //     "--AaB03x" +
        //     "Content-Disposition: file" +
        //     "Content-Type: png/jpg/jpeg" +
        //     "Content-Transfer-Encoding: binary" +
        //     "...data... " +
        //     "--AaB03x--",
        //   "Accept": "application/json",
        //   "type": "formData",
        // },
      };
      const url = `${Baseurl}/api/v1/saloonCategory/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
export const saloonCategoryDelete = createAsyncThunk(
  "saloonCategory/categoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/saloonCategory/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

const SaloonCategorySlice = createSlice({
  name: "saloonCategoryAll",
  initialState,
  reducers: {
    updateCatMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.isCatmobileImageLoading = false;
    },
    updateCatDeskimage(state, action) {
      state.desktopimage = action.payload;
      state.isDeskImageLoading = false;
    },
    deleteCategory(state, action) {
      state.categorytotal = state.categorytotal.filter(
        (category) => category._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSaloonCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSaloonCategory.fulfilled, (state, action) => {
        state.categorytotal = action.payload;
        localStorage.setItem(
          "saloonCategorytotal",
          JSON.stringify(state.categorytotal)
        );
        state.isLoading = false;
        state.categoryLoading = false;
      })
      .addCase(getSaloonCategory.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(saloonCategoryPost.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(saloonCategoryPost.fulfilled, (state,action) => {
        if (action.payload.success) {
          state.categorytotal = [
            ...state.categorytotal,
            action.payload.category,
          ].sort();
          localStorage.setItem(
            "saloonCategorytotal",
            JSON.stringify(state.categorytotal)
          );
          state.mobileimage = "";
          state.desktopimage = "";
          state.isCatmobileImageLoading = true;
          state.isDeskImageLoading = true;
        }
        state.categoryLoading = false;
        state.checkSlugurl = false;
      })
      .addCase(saloonCategoryPost.rejected, (state) => {
        state.categoryLoading = true;
      })
      .addCase(categoryMobileImage.pending, (state) => {
        state.isCatmobileImageLoading = true;
      })
      .addCase(categoryMobileImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
        }
        state.isCatmobileImageLoading = false;
      })
      .addCase(categoryMobileImage.rejected, (state) => {
        state.isCatmobileImageLoading = true;
      })
      .addCase(categoryDesktopImage.pending, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(categoryDesktopImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
        }
        state.isDeskImageLoading = false;
      })
      .addCase(categoryDesktopImage.rejected, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(saloonCategoryImages.pending, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(saloonCategoryImages.fulfilled, (state,action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
          state.mobileimage = action.payload.mobileimages;
        }
        state.isDeskImageLoading = false;
        state.isCatmobileImageLoading = false;
      })
      .addCase(saloonCategoryImages.rejected, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(saloonCategoryUpdate.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(saloonCategoryUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.categorytotal = state.categorytotal.filter(
            (category) => category._id !== action.payload.category._id
          );
          state.categorytotal = [
            ...state.categorytotal,
            action.payload.category,
          ];
          state.mobileimage = "";
          state.desktopimage = "";
          state.isCatmobileImageLoading = true;
          state.isDeskImageLoading = true;
          localStorage.setItem(
            "categorytotal",
            JSON.stringify(state.categorytotal)
          );
        }

        state.categoryLoading = false;
      })
      .addCase(saloonCategoryUpdate.rejected, (state) => {
        state.categoryLoading = true;
      })
      .addCase(saloonCategoryDelete.pending, (state) => {
        state.deleteCatLoading = true;
      })
      .addCase(saloonCategoryDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.categorytotal = state.categorytotal.filter(
            (category) => category._id !== action.payload.id
          );
          localStorage.setItem(
            "saloonCategorytotal",
            JSON.stringify(state.categorytotal)
          );
        }
        state.deleteCatLoading = false;
      })
      .addCase(saloonCategoryDelete.rejected, (state) => {
        state.deleteCatLoading = true;
      });
  },
});
export const { updateCatMobileImage, updateCatDeskimage, deleteCategory } =
  SaloonCategorySlice.actions;
export default SaloonCategorySlice.reducer;
